import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { colors } from '../variables'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import {
  SubHeader,
  Ingress,
  ButtonMain,
  ImgSet,
  Payoff,
  ImageBlock,
  PageWrapper,
  PixelStairs,
} from '../components/Utils'
import { useHistory } from 'react-router-dom'

import hero from '../img/photos/hero.jpg'
import writing from '../img/photos/writing.jpg'
import dog from '../img/photos/dog.jpg'
import baby from '../img/photos/baby_square.jpg'
import { UIStore } from '../stores/UIStore'
import { useTranslation } from 'react-i18next'

const Main = observer(() => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    UIStore.setBackgroundShade(colors.green)
  }, [])

  return (
    <>
      <LandingArea />
      <Hero />
      <PageWrapper
        style={{
          backgroundColor: colors.white,
        }}
      >
        <Container>
          <DynamicRow>
            <Col xs={12} lg={6}>
              <BottomContentBlock>
                <SubHeader shade="dark">{t('home.duifokus.header')}</SubHeader>
                <Ingress shade="dark" style={{ marginTop: 30 }}>
                  {t('home.duifokus.text')}
                </Ingress>
                <ButtonMain
                  onClick={() => {
                    history.push('/work')
                    window.scrollTo(0, 0)
                  }}
                >
                  {t('Jobba med oss')}
                </ButtonMain>
              </BottomContentBlock>
            </Col>
            <Hidden xs sm md>
              <Col xs={12} md={6}>
                <ImageBlock style={{ top: '-90px' }}>
                  <StyledImgSet img={writing} className="block1-img1" />
                  <StyledImgSet img={dog} className="block1-img2" />
                  <StyledImgSet img={baby} className="block1-img3" />
                </ImageBlock>
              </Col>
            </Hidden>
          </DynamicRow>
        </Container>
      </PageWrapper>
    </>
  )
})

export const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <>
      <LandingHolder>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <h1>{t('home.header')}</h1>
            </Col>
            <Visible lg>
              <Col lg={1} />
            </Visible>
            <Col
              xs={12}
              lg={5}
              xl={6}
              style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}
            >
              <Payoff>{t('home.payoff')}</Payoff>
            </Col>
          </Row>
        </Container>
      </LandingHolder>
    </>
  )
}

export const Hero = () => {
  const { t } = useTranslation()

  return (
    <>
      <PixelStairs color={colors.orange} backgroundImage={hero} height={1050}>
        <WelcomeBlock>
          <SubHeader>{t('home.hero.header')}</SubHeader>
          <Payoff>{t('home.hero.text')}</Payoff>
        </WelcomeBlock>
      </PixelStairs>
    </>
  )
}

const LandingHolder = styled.div`
  height: auto;
  padding: 125px 0 40px;
  box-sizing: border-box;

  h1 {
    margin-bottom: 30px;
  }

  @media (min-width: 769px) {
    padding-top: 200px;
    padding-bottom: 150px;

    h1 {
      margin-bottom: 0;
    }
  }
`

const DynamicRow = styled(Row)`
  flex-direction: column-reverse;
  margin-top: 20px;

  @media (min-width: 769px) and (max-width: 991px) {
    margin-top: 50px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    margin-top: 140px;
  }

  & > div:first-child {
    @media (min-width: 570px) {
      margin-top: 0;
    }
  }
`
const WelcomeBlock = styled.div`
  margin: 0;
  width: 100%;

  ${Payoff} {
    max-width: 100%;
  }
`

const BottomContentBlock = styled.div`
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-bottom: 280px;
  }
`

export const StyledImgSet = styled(ImgSet)`
  &.block1-img1 {
    top: 0;
    left: 0;
    width: 400px;

    @media (min-width: 420px) {
      top: 100px;
      left: 340px;
    }

    @media (min-width: 769px) {
      top: 0;
      left: 60px;
    }
  }
  &.block1-img2 {
    top: 145px;
    left: 500px;
    width: 450px;
  }
  &.block1-img3 {
    top: 307px;
    left: 120px;
    width: 340px;
  }
  &.block1-img4 {
    top: 463px;
    left: 241px;
    width: 300px;
  }

  &.block2-img1 {
    top: 60px;
    left: -50px;
  }
  &.block2-img2 {
    top: 0;
    left: 270px;
  }
  &.block2-img3 {
    top: 414px;
    left: 270px;
  }
`

export default Main
