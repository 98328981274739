import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components'
import { Payoff, SubHeader } from '../components/Utils'
import { useTranslation } from 'react-i18next'

import emanuel from '../img/photos/contact/emanuel.jpeg'
import anders from '../img/photos/contact/anders.jpg'
import lezgin from '../img/photos/contact/lezgin.jpeg'
import stephanie from '../img/photos/contact/stephanie.jpg'

import linkedin from '../img/icons/linkedin.svg'
import email from '../img/icons/email.svg'

const Contact = observer(() => {
  useEffect(() => {
    UIStore.setBackgroundShade(colors.white)
    UIStore.setFooterBg(colors.white)
  }, [])

  const { t } = useTranslation()

  return (
    <>
      <LandingArea />
      <ContactPerson
        contactperson={{
          img: emanuel,
          person: 'Emanuel Fratini',
          title: t('contact.emanuel.title'),
          text: t('contact.emanuel.tagline'),
          linkedin: 'https://www.linkedin.com/in/emanuel-fratini-7050297/',
          email: 'contact@utvecklarbolaget.se',
        }}
      />
      <ContactPerson
        contactperson={{
          img: anders,
          person: 'Anders Holen',
          title: t('contact.anders.title'),
          text: t('contact.anders.tagline'),
          imgRight: true,
          linkedin: 'https://www.linkedin.com/in/andersholen/',
          email: 'ah@utvecklarbolaget.se',
          phone: '0709-161967',
        }}
      />
      <ContactPerson
        contactperson={{
          img: lezgin,
          person: 'Lezgin Serin',
          title: t('contact.lezgin.title'),
          text: t('contact.lezgin.tagline'),
          imgRight: false,
          linkedin: 'https://www.linkedin.com/in/lezgin-serin-bb893a2/',
          email: 'ls@utvecklarbolaget.se',
          phone: '0723-311158',
        }}
      />
      <ContactPerson
        contactperson={{
          img: stephanie,
          person: 'Stephanie Touma',
          title: t('contact.stephanie.title'),
          text: t('contact.stephanie.tagline'),
          imgRight: true,
          linkedin: 'https://www.linkedin.com/in/stephanie-touma-637142a6/',
          email: 'stephanie.touma@utvecklarbolaget.se',
          phone: '0703-333259',
        }}
      />
    </>
  )
})

export const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <>
      <LandingHolder>
        <Container>
          <Row>
            <Col xs={12}>
              <h1>{t('Kontakta oss')}</h1>
            </Col>
          </Row>
        </Container>
      </LandingHolder>
    </>
  )
}

type ContactPersonProps = {
  img: string
  person: string
  title: string
  text: string
  linkedin: string
  email: string
  phone?: string
  imgRight?: boolean
}

export const ContactPerson = ({
  contactperson,
}: {
  contactperson: ContactPersonProps
}) => {
  const [phone, setPhone] = useState(contactperson.phone)
  const { t } = useTranslation()

  useEffect(() => {
    setPhone(contactperson.phone)
  }, [contactperson.phone])

  return (
    <ContactPersonHolder imgRight={contactperson.imgRight}>
      <ContactPersonImg img={contactperson.img} />
      <ContactPersonTextHolder>
        <div style={{ position: 'relative' }}>
          <SubHeader style={{ marginBottom: 0 }}>
            {contactperson.person}
          </SubHeader>
          <span
            style={{
              fontStyle: 'italic',
              display: 'inline-block',
              marginBottom: 20,
            }}
          >
            {contactperson.title}
          </span>
          <Payoff
            style={{ flexBasis: 'auto', width: 'auto', textAlign: 'center' }}
          >
            {contactperson.text}
          </Payoff>
          <span
            style={{
              display: 'block',
              marginTop: 20,
            }}
          >
            {phone && `Tel: ${phone}`}
          </span>
          <img
            alt={t('Kontakta mig via email')}
            title={t('Kontakta mig via email')}
            src={email}
            style={{ width: 40, marginTop: 20 }}
            onClick={() => sendEmail(contactperson.email)}
          />
          <a href={contactperson.linkedin}>
            <img
              src={linkedin}
              alt={t('Kontakta mig via LinkedIn')}
              title={t('Kontakta mig via LinkedIn')}
              style={{ width: 40, marginTop: 20 }}
            />
          </a>
        </div>
      </ContactPersonTextHolder>
    </ContactPersonHolder>
  )
}

function sendEmail(email: string) {
  window.location.href = `mailto:${email}`
}

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
  margin-bottom: 40px;

  h1 {
    margin-bottom: 20px;
  }

  @media (min-width: 769px) {
    margin-bottom: 80px;
  }
`

const ContactPersonHolder = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;

  @media (min-width: 769px) {
    flex-direction: ${({ imgRight }: { imgRight: boolean }) =>
      imgRight ? 'row-reverse' : 'row'};
    height: 680px;
  }
`

const ContactPersonImg = styled.div`
  background-image: url(${({ img }: { img: string }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-width: 100vw;
  height: 450px;

  @media (min-width: 769px) {
    min-width: 50vw;
    height: 100%;
  }
`

const ContactPersonTextHolder = styled.div`
  background-color: ${colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 70px;
  position: relative;
  text-align: center;
  box-sizing: border-box;

  h2 {
    margin-top: 0;
  }

  img {
    cursor: pointer;
  }

  @media (min-width: 769px) {
    padding: 0 120px;
    max-width: 50vw;
  }
`

export default Contact
