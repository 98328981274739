import React from 'react'
import styled from 'styled-components'
import { colors } from '../variables'

import arrow from '../img/icons/ub-pil-90-orange-rgb.svg'
import { Hidden, Visible } from 'react-grid-system'

type Props = {
  children: any
  className?: React.CSSProperties
}

export const MainTextHolder = ({ children, className }: Props) => {
  return <MainText className={className}>{children}</MainText>
}

export const SubHeader = ({
  shade,
  children,
  style,
}: {
  shade?: string
  children: any
  style?: React.CSSProperties
}) => {
  return (
    <StyledH2 style={style} shade={shade}>
      {children}
    </StyledH2>
  )
}

const StyledH2 = styled.h2`
  color: ${({ shade }: { shade: string }) =>
    shade === 'dark' ? colors.green : colors.white};
  margin-bottom: 15px;
  margin-top: 30px;

  @media (min-width: 769px) {
    margin-top: 0;
  }
`

export const ImgSet = ({
  img,
  style,
  className,
}: {
  img: string
  style?: React.CSSProperties
  className?: string
}) => {
  const name = img.substring(0, img.lastIndexOf('.'))
  const suffix = img.substring(img.lastIndexOf('.'))

  return (
    <img
      src={img}
      data-srcset={`${img} 1x, ${name}@2x${suffix}, ${name}@3x${suffix}`}
      alt=""
      style={style}
      className={className}
    />
  )
}

export const Ingress = styled.p`
  font-size: 15px;
  line-height: 21px;
  color: ${({ shade }: { shade: string }) =>
    shade === 'dark' ? colors.green : colors.white};
  white-space: pre-line;

  @media (min-width: 769px) {
    font-size: 18px;
    line-height: 1.45;
  }
`

export const Maintext = styled.p`
  font-family: Gelion;
  color: ${({ shade }: { shade: string }) =>
    shade === 'dark' ? colors.green : colors.white};
  font-size: 18px;
  line-height: 26px;
  white-space: pre-line;
`

export const ButtonMain = styled.button`
  padding: 10px 40px 10px 15px;
  border: 2px solid ${colors.green};
  background-color: ${colors.white};
  color: ${colors.green};
  font-family: Gelion;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  margin-top: 30px;
  cursor: pointer;

  &:after {
    position: absolute;
    right: 15px;
    top: 11px;
    width: 15px;
    height: 20px;
    background-image: url('${arrow}');
    background-repeat: no-repeat;
    z-index: 1;
    content: '';
  }
`

export const ButtonSmall = styled.button`
  padding: 4px 26px 4px 8px;
  border: 2px solid ${colors.green};
  background-color: ${colors.white};
  color: ${colors.green};
  font-family: Gelion;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  margin-top: 12px;
  cursor: pointer;

  &:after {
    position: absolute;
    right: 4px;
    top: 7px;
    width: 15px;
    height: 12px;
    background-image: url('${arrow}');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    content: '';
  }
`

const MainText = styled.div`
  text-align: center;
  padding: 40px;
  box-sizing: border-box;

  @media (min-width: 769px) {
    width: 980px;
    max-width: 100%;
    margin: 0px auto;
    padding: 60px;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 17px;
  }

  h3 {
    font-size: 32px;
    line-height: 40px;
    color: #fff;

    @media (min-width: 769px) {
      font-size: 42px;
      line-height: 50px;
    }
  }

  > h4 {
    margin-top: 0;
  }

  figure {
    width: 100%;
    margin: 0;

    > img {
      width: 100%;
    }
  }
`

export const Spacer = styled.div`
  width: 100%;
`

export const Payoff = styled.div`
  font-size: 17px;
  line-height: 1.55;
  width: 100%;
  max-width: 570px;
  font-family: 'Miriam Libre';
  color: ${({ shade }: { shade?: string }) =>
    shade === 'dark' ? colors.green : colors.white};
  white-space: pre-line;

  @media (min-width: 769px) {
    font-size: 22px;
  }
`

export const getSlug = (link: string) => {
  if (link.substr(link.length - 1) === '/') {
    link = link.substr(0, link.length - 1)
  }
  link = link.substr(link.lastIndexOf('/'))

  return link
}

export const PixelStairs = ({
  background,
  contentBackground,
  backgroundImage,
  height,
  color,
  children,
}: {
  background?: any
  contentBackground?: any
  backgroundImage?: any
  height?: number
  color?: any
  children: React.ReactNode
}) => {
  const Inner = ({
    backgroundImage = false,
  }: {
    backgroundImage?: boolean
  }) => (
    <Holder backgroundImage={backgroundImage} background={background}>
      {backgroundImage && <div />}
      <PixelRow1 backgroundImage={backgroundImage} />
      <PixelRow2 backgroundImage={backgroundImage} color={color} />
      <PixelRow3 backgroundImage={backgroundImage} color={color} />
      <PixelStairsContent
        background={contentBackground}
        backgroundImage={backgroundImage}
      >
        {children}
      </PixelStairsContent>
    </Holder>
  )

  if (backgroundImage) {
    return (
      <>
        <Hidden xs sm md>
          <BackgroundImage image={backgroundImage} height={height}>
            <Inner backgroundImage={true} />
          </BackgroundImage>
        </Hidden>
        <Visible xs sm md>
          <img src={backgroundImage} alt="Hero" style={{ maxWidth: '100%' }} />
          <InnerMobile style={{ backgroundColor: colors.orange, padding: 40 }}>
            {children}
          </InnerMobile>
        </Visible>
      </>
    )
  }

  return (
    <>
      <Hidden xs sm>
        <Inner />
      </Hidden>
      <Visible xs sm>
        <InnerMobile>{children}</InnerMobile>
      </Visible>
    </>
  )
}

const BackgroundImage = styled.div`
  background-image: url(${({ image }: { image: any }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  height: ${({ height }: { height: number }) =>
    height ? `${height}px` : 'auto'};
  display: grid;
  align-content: flex-end;
`

const Holder = styled.div`
  display: grid;
  grid-template-columns: 280px auto;
  width: 100%;
  background-color: ${({ background }: { background: any }) =>
    background ? background : 'transparent'};
  ${({ backgroundImage }: { backgroundImage: boolean }) =>
    backgroundImage
      ? `
      grid-template-rows: auto 140px 140px auto;
  `
      : `  grid-template-rows: 140px 140px auto;`}
`

const PixelRow1 = styled.div`
  width: 100%;
  height: 140px;
  ${({ backgroundImage }: { backgroundImage: boolean }) =>
    backgroundImage
      ? `
    grid-row-start: 2;
    grid-column-start: 1;
  `
      : ''}
`
const PixelRow2 = styled.div`
  background-color: ${({ color }: { color: any }) => color};
  width: 140px;
  height: 140px;
  justify-self: right;
  ${({ backgroundImage }: { backgroundImage: boolean }) =>
    backgroundImage
      ? `
    grid-row-start: 3;
    grid-column-start: 1;
  `
      : `grid-row-start: 2;`}
`
const PixelRow3 = styled.div`
  background-color: ${({ color }: { color: any }) => color};
  width: 100%;
  min-height: 140px;
  ${({ backgroundImage }: { backgroundImage: boolean }) =>
    backgroundImage
      ? `
    grid-row-start: 4;
    grid-column-start: 1;
  `
      : `grid-row-start: 3;`}
`
const PixelStairsContent = styled.div`
  background-color: ${({ background }: { background: any }) =>
    background ? background : colors.orange};
  padding: 60px 60px 60px 80px;
  ${({ backgroundImage }: { backgroundImage: boolean }) =>
    backgroundImage
      ? `
    grid-row-start: 2;
    grid-row-end: span 3;
    grid-column-start: 2;
  `
      : `grid-row-start: span 3;`}
`

export const scrollToDiv = (pageLink: string, offset?: number) => {
  var element = document.getElementById(pageLink) || document.body
  var headerOffset = offset || 80
  var elementPosition = element.offsetTop
  var offsetPosition = elementPosition - headerOffset

  window.scrollTo({
    top: offsetPosition,
    left: 0,
    behavior: 'smooth',
  })
}

export const ImageBlock = styled.div`
  position: absolute;

  > img {
    position: absolute;
  }
`

export const PageWrapper = styled.section`
  padding: 0 20px;
  display: flex;

  @media (min-width: 769px) {
    padding: 0 40px;
  }
`

const InnerMobile = styled.div`
  background-color: ${colors.orange};
  padding: 40px;

  h2 {
    margin-top: 0;
  }
`
