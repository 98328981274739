import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Maintext, Payoff } from '../components/Utils'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import storytel from '../img/photos/references/storytel.jpg'
import dn from '../img/photos/references/dn.jpg'
import klarna from '../img/photos/references/klarna.jpg'
import svenskaspel from '../img/photos/references/svenskaspel.jpeg'
import postnord from '../img/photos/references/postnord.jpg'
import { useTranslation } from 'react-i18next'

const References = observer(() => {
  useEffect(() => {
    UIStore.setBackgroundShade(colors.green)
    UIStore.setFooterBg(colors.white)
  }, [])

  const { t } = useTranslation()

  const references = [
    {
      id: 'storytel',
      name: t('references.storytel.header'),
      img: storytel,
      ingress: t('references.storytel.ingress'),
      bread: t('references.storytel.text'),
    },
    {
      id: 'dagensnyheter',
      name: t('references.dagensnyheter.header'),
      img: dn,
      ingress: t('references.dagensnyheter.ingress'),
      bread: t('references.dagensnyheter.text'),
    },
    {
      id: 'klarna',
      name: t('references.klarna.header'),
      img: klarna,
      ingress: t('references.klarna.ingress'),
      bread: t('references.klarna.text'),
    },
    {
      id: 'svenskaspel',
      name: t('references.svenskaspel.header'),
      img: svenskaspel,
      ingress: t('references.svenskaspel.ingress'),
      bread: t('references.svenskaspel.text'),
    },
    {
      id: 'postnord',
      name: t('references.postnord.header'),
      img: postnord,
      ingress: t('references.postnord.ingress'),
      bread: t('references.postnord.text'),
    },
  ]

  return (
    <>
      <LandingArea />
      {references.map((reference) => (
        <React.Fragment key={reference.id}>
          <ReferenceTitle>{reference.name}</ReferenceTitle>
          <ReferenceImage
            style={{ backgroundImage: `url('${reference.img}')` }}
          />
          <ReferenceContent>
            <Row>
              <Hidden xs sm md>
                <Col lg={1} />
              </Hidden>
              <Col xs={12} md={5} lg={4}>
                <Payoff
                  dangerouslySetInnerHTML={{ __html: reference.ingress }}
                />
              </Col>
              <Hidden xs sm>
                <Col md={1} />
              </Hidden>
              <Col xs={12} md={6} lg={5}>
                <Maintext
                  dangerouslySetInnerHTML={{ __html: reference.bread }}
                />
              </Col>
              <Hidden xs sm md>
                <Col lg={1} />
              </Hidden>
            </Row>
          </ReferenceContent>
        </React.Fragment>
      ))}
      <div style={{ height: 20 }} />
    </>
  )
})

const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <LandingHolder>
      <Container style={{ marginBottom: 100 }}>
        <Row>
          <Col>
            <h1>{t('references.header')}</h1>
          </Col>
        </Row>
      </Container>
    </LandingHolder>
  )
}

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
`

const ReferenceImage = styled.div`
  display: block;
  height: 450px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 60px;
`

const ReferenceContent = styled(Container)`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto 120px;

  @media (min-width: 769px) {
    width: 100vw;
  }

  ${Payoff} {
    margin-bottom: 40px;
  }

  ${Maintext} {
    margin-top: 0;
  }
`

const ReferenceTitle = styled.h2`
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto 20px;
  font-weight: 600;
  font-size: 42px;
  line-height: 54px;
  color: ${colors.white};
  margin-bottom: 40px;

  @media (min-width: 769px) {
    margin: 0 auto 40px;
    font-size: 64px;
    line-height: 70px;
  }
`

/*const ReferenceContent = styled(motion.div)`
  color: ${colors.white};
`*/

export default References
