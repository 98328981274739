import { createGlobalStyle } from 'styled-components'
import { colors } from './variables'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Gelion;
  }

  html {
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;
  }

  h1 {
    font-size: 48px;
    line-height: 46px;
    color: ${colors.orange};
    font-weight: 700;

    @media (min-width: 769px) {
      font-size: 104px;
      line-height: 96px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 1.28;

    @media (min-width: 769px) {
      font-size: 36px;
    }
  }

  h3 {
  }

  h1,
  h2,
  h3,
  h4,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 0;
    white-space: pre-line;
  }

  a {
    color: #f96d30;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      margin: 0;
      padding: 0;
    }
  }

  strong {
    &:before, &:after {
      content: ' '
    }
  }
`

export default GlobalStyle
