import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import styled from 'styled-components'
import { Payoff, Ingress, PageWrapper, SubHeader } from '../components/Utils'
import { Container, Row, Col } from 'react-grid-system'
import { useHistory } from 'react-router-dom'
import stories from '../stories'

import arrow from '../img/icons/ub-pil-90-orange-rgb.svg'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

export type StoryProps = {
  id: string
  name: string
  headline?: string
  ingress?: string
  bread?: string
  img: string
  imgRight?: boolean
  color?: string
  alignment?: string
  link?: string
}

const Story = observer(({ story }: { story: any }) => {
  const history = useHistory()
  const [jobs, setJobs] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    UIStore.setBackgroundShade(colors.green)
    UIStore.setFooterBg(colors.white)

    let arrJobs = [] as any

    const axiosInstance = axios.create({
      baseURL: 'https://api.teamtailor.com/v1',
      headers: {
        Authorization: 'Bearer gHg9ADEP6OsBNFGgJEns7mDo3_rXf5n2SaUVMxVU',
        'X-Api-Version': '20201130',
        Accept: 'application/vnd.api+json',
      },
    })

    axiosInstance
      .get('/jobs', {
        params: { 'filter%5Bstatus%5D': 'published' },
      })
      .then((response) => {
        response.data.data.map((job: any) => arrJobs.push(job))
        setJobs(arrJobs)
      })
  }, [])

  let previousStory: StoryProps & any
  let nextStory: StoryProps & any

  for (let i = 0; i < stories.length; i++) {
    if (stories[i].id === story.id) {
      previousStory = stories[i - 1]
      nextStory = stories[i + 1]
    }
  }

  let activeStory: any = stories.filter((obj) => obj.id === story.id)
  activeStory = activeStory[0]

  return (
    <>
      <HeroHolder>
        <Overlay />
        <HeroImg src={activeStory.img} />
        <Container>
          <Row>
            <Col>
              <HeadlineHolder>
                <Headline>{activeStory.name}</Headline>
                <h2>{t(`stories.${activeStory.id}.headline`)}</h2>
              </HeadlineHolder>
            </Col>
          </Row>
        </Container>
      </HeroHolder>
      <PageWrapper style={{ backgroundColor: colors.green }}>
        <StyledContainer>
          <Row>
            <Col xs={12} md={6}>
              <Payoff
                dangerouslySetInnerHTML={{
                  __html: t(`stories.${activeStory.id}.ingress`),
                }}
              />
            </Col>
            <Col xs={12} md={6}>
              <StyledContent
                dangerouslySetInnerHTML={{
                  __html: t(`stories.${activeStory.id}.bread`),
                }}
              />
              <hr style={{ margin: '60px 0 40px' }} />
              <SubHeader>{t('story.cta')}</SubHeader>
              <Ingress>{t('Just nu söker vi')}:</Ingress>
              {jobs && jobs.length > 0 && (
                <ul>
                  {jobs &&
                    jobs.map((job: any) => {
                      const jobDate = new Date(job.attributes['created-at'])

                      return (
                        <li
                          onClick={() => {
                            //document.location.href = job.links['careersite-job-url']
                            history.push(`/job/${job.id}`)
                            window.scrollTo(0, 0)
                          }}
                          style={{
                            margin: '20px 0',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <div
                              style={{
                                width: 30,
                                height: 40,
                                backgroundImage: `url(${arrow})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '20px',
                                backgroundPosition: 'center center',
                                marginRight: 20,
                              }}
                            />
                          </div>
                          <div>
                            <Payoff
                              style={{ color: colors.orange, marginBottom: 0 }}
                            >
                              {job.attributes.title}
                            </Payoff>
                            <Ingress style={{ marginTop: 0, marginBottom: 0 }}>
                              {t('Publicerat')}{' '}
                              {`${jobDate.getFullYear()}-${(
                                '0' +
                                (jobDate.getMonth() + 1)
                              ).slice(-2)}-${jobDate.getDate()}`}
                            </Ingress>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              )}
            </Col>
          </Row>
        </StyledContainer>
      </PageWrapper>
      <StoryLinksRow>
        {!previousStory && <Col />}
        {previousStory !== undefined && (
          <StoryLinkCol
            xs={12}
            md={6}
            onClick={() => {
              history.push(`/story/${previousStory?.id}`)
              window.scrollTo(0, 0)
            }}
            style={{ textAlign: 'right' }}
            bg={previousStory.img}
            alignment={previousStory?.alignment}
          >
            <StoryLink style={{ right: 40 }}>
              {t('stories.Föregående story')}
              <br />
              <span>{previousStory?.name}</span>
            </StoryLink>
          </StoryLinkCol>
        )}
        {!nextStory && <Col />}
        {nextStory !== undefined && (
          <StoryLinkCol
            xs={12}
            md={6}
            onClick={() => {
              history.push(`/story/${nextStory?.id}`)
              window.scrollTo(0, 0)
            }}
            style={{ textAlign: 'left' }}
            bg={nextStory.img}
            alignment={nextStory?.alignment}
          >
            <StoryLink style={{ left: 40 }}>
              {t('stories.Nästa story')}
              <br />
              <span>{nextStory?.name}</span>
            </StoryLink>
          </StoryLinkCol>
        )}
      </StoryLinksRow>
    </>
  )
})

const StyledContainer = styled(Container)`
  margin: 30px 0;

  @media (min-width: 769px) {
    margin-top: 85px;
    margin-bottom: 0;
  }
`

const HeadlineHolder = styled.div`
  position: absolute;
  bottom: 20px;
  width: 80%;

  @media (min-width: 769px) {
    bottom: 30px;
  }

  h1 {
    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 18px;
    color: ${colors.white};

    @media (min-width: 576px) {
      font-size: 26px;
      line-height: 30px;
    }

    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 46px;
      margin-top: 20px;
    }
  }
`

const Headline = styled.h1``

const HeroHolder = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.6) 90%
  );
`

const HeroImg = styled.img`
  width: 100%;
  margin-top: 120px;
`

const StyledContent = styled(Ingress)`
  margin-top: 40px;

  @media (min-width: 769px) {
    margin-top: 0;
  }

  .blockquote-wrapper {
    display: flex;
    padding: 0 20px;

    blockquote {
      position: relative;
      font-family: 'Barlow Condensed', sans-serif;
      margin: 40px auto 60px;
      align-self: center;

      h1 {
        font-family: 'Miriam Libre';
        position: relative; /* for pseudos */
        color: ${colors.white};
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.3;
        margin: 0;
        border: 2px solid ${colors.orange};
        padding: 25px;

        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: 60px;
          bottom: -52px;
          left: 70px;
          z-index: 3;
          transform: rotate(-45deg);
          background-color: ${colors.orange};
        }

        &:before {
          content: '';
          position: absolute;
          width: 40px;
          border: 6px solid ${colors.green};
          bottom: -3px;
          left: 50px;
          z-index: 2;
        }
      }

      h4 {
        position: relative;
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        padding-top: 15px;
        z-index: 1;
        margin-left: 120px;
        padding-left: 12px;

        &:first-letter {
          margin-left: -12px;
        }
      }
    }
  }
`

const StoryLinksRow = styled(Row)`
  margin-top: 80px;
`

const StoryLinkCol = styled(Col)`
  cursor: pointer;
  background-image: ${({ bg }: { bg: string }) => `url(${bg})`};
  background-size: cover;
  background-position: ${({ alignment }: { alignment: string }) =>
    alignment === 'right' ? 'left center' : 'right center'};
  height: 400px;
  width: 100%;
  position: relative;
`

const StoryLink = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-family: 'Gelion';
  font-weight: 700;
  font-size: 24px;
  color: ${colors.white};
  text-align: center;

  @media (max-width: 768px) {
    left: 0 !important;
    right: 0 !important;
  }

  @media (min-width: 769px) {
    text-align: inherit;
    width: auto;
  }

  > span {
    color: ${colors.orange};
    font-size: 32px;

    @media (min-width: 769px) {
      font-size: 40px;
    }
  }
`

export default Story
