import React, { createRef, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { setConfiguration } from 'react-grid-system'

import Header from './components/Header'
import Footer from './components/Footer'

import Main from './pages/Main'
import Work from './pages/Work'
import Customers from './pages/Customers'
import References from './pages/References'
import About from './pages/About'
import Contact from './pages/Contact'

import GlobalStyle from './globalStyles.ts'

import { UIStore } from './stores/UIStore'
import Story from './pages/Story'
import stories from './stories'
import Career from './pages/Career'
import Job from './pages/Job'
import { colors } from './variables'

const App = observer(() => {
  const [numConsoles, setNumConsoles] = useState(0)

  setConfiguration({
    breakpoints: [576, 768, 992, 1280],
    containerWidths: [540, 750, 960, 1220],
  })

  if (numConsoles === 0) {
    console.log(
      "%cChecking out our code?%cWhy don't you join us instead? Head on to our career page here: http://utvecklarbolaget.se/career\nHope to hear from you!\n/Utvecklarbolaget",
      `text-shadow: 1px 1px ${colors.green}; font-size: 20px; font-weight: 700; border-left: 12px solid ${colors.green}; padding: 8px;`,
      `font-size: 14px; border-left: 12px solid ${colors.green}; padding: 8px;`
    )
    setNumConsoles(1)
  }

  const footerRef = createRef()

  const getFooterHeight = () => {
    // @ts-ignore
    const fh = Math.round(footerRef.current.getBoundingClientRect().height)
    return fh
  }

  window.addEventListener('resize', () => {
    if (footerRef.current !== null) {
      UIStore.setFooterHeight(getFooterHeight())
    }
  })

  useEffect(() => {
    UIStore.setFooterHeight(getFooterHeight())
  })

  return (
    <>
      <div style={{ backgroundColor: UIStore.backgroundShade }}>
        <GlobalStyle />
        <Router>
          <Modal />
          <Header />
          <PageWrapper>
            <Switch>
              <Route exact path="/" render={() => <Main />} />
              <Route exact path="/work" render={() => <Work />} />
              <Route exact path="/career" render={() => <Career />} />
              <Route exact path="/customers" render={() => <Customers />} />
              <Route exact path="/references" render={() => <References />} />
              <Route exact path="/stories" render={() => <About />} />
              <Route exact path="/contact" render={() => <Contact />} />
              <Route
                path="/story/:storyId"
                render={({ match }: { match: any }) => (
                  <Story
                    story={stories.find((s) => s.id === match.params.storyId)}
                  />
                )}
              />
              <Route
                path="/job/:jobId"
                render={({ match }: { match: any }) => (
                  <Job jobId={match.params.jobId} />
                )}
              />
            </Switch>
          </PageWrapper>
          <Footer ref={footerRef} />
        </Router>
      </div>
    </>
  )
})

const PageWrapper = styled.div`
  padding: 0;
`

const Modal = () => (
  <div
    className="modalHolder"
    style={{
      width: '100%',
      height: '100%',
      position: 'fixed',
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      zIndex: 10000,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    }}
  >
    <svg
      className="svg-icon"
      viewBox="0 0 20 20"
      style={{ width: 50, marginBottom: 20, cursor: 'pointer' }}
      onClick={() =>
        // @ts-ignore
        (document.getElementsByClassName('modalHolder')[0].style.display =
          'none')
      }
    >
      <path
        stroke="#ffffff"
        d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"
      ></path>
    </svg>
    <iframe
      className="modal"
      style={{
        maxWidth: 600,
        width: '100%',
        height: 800,
        border: 0,
      }}
      src=""
      title="Ansökan"
    />
  </div>
)

export default App
