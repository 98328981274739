import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import MenuItems from './MenuItems'

export default function Menu() {
  return (
    <>
      <StyledMenu>
        <MenuItems />
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled(motion.div)`
  text-align: center;
  margin-left: 60px;

  @media (max-width: 768px) {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
`
