import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import styled from 'styled-components'
import { Ingress, PageWrapper, ButtonMain } from '../components/Utils'
import { Container, Row, Col } from 'react-grid-system'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import code from '../img/code.png'

const Job = observer(({ jobId }: { jobId: number }) => {
  const [job, setJob] = useState({} as any)
  const [jobLocation, setJobLocation] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: 'https://api.teamtailor.com/v1',
      headers: {
        Authorization: 'Bearer gHg9ADEP6OsBNFGgJEns7mDo3_rXf5n2SaUVMxVU',
        'X-Api-Version': '20201130',
        Accept: 'application/vnd.api+json',
      },
    })

    axiosInstance
      .get(`/jobs/${jobId}`, {
        params: { 'filter%5Bstatus%5D': 'published', include: 'locations' },
      })
      .then((response) => {
        setJob(response.data.data)

        const locationId = response.data.data.relationships.locations.data[0].id

        axiosInstance.get(`/locations/${locationId}`).then((response) => {
          const jobLocation = response.data.data.attributes.city
          setJobLocation(jobLocation)
        })
      })
  }, [jobId])

  useEffect(() => {
    UIStore.setBackgroundShade(colors.green)
    UIStore.setFooterBg(colors.white)
  }, [])

  const heroImg =
    job.attributes && job.attributes.picture && job.attributes.picture.standard

  return (
    <>
      <HeroHolder>
        <HeroImg src={job && job.attributes && (heroImg ? heroImg : code)} />
        <Container>
          <Row>
            <Col>
              <HeadlineHolder>
                <Headline>{job.attributes && job.attributes.title}</Headline>
                <h2>
                  {jobLocation}
                  {job.attributes &&
                  job.attributes['remote-status'] === 'hybrid'
                    ? ' / Flexible Remote'
                    : ''}
                </h2>
              </HeadlineHolder>
            </Col>
          </Row>
        </Container>
      </HeroHolder>
      <PageWrapper style={{ backgroundColor: colors.green }}>
        <StyledContainer>
          <Row>
            <Col md={2} />
            <Col xs={12} md={8}>
              <StyledContent
                dangerouslySetInnerHTML={{
                  __html: job.attributes && job.attributes.body,
                }}
              />
              <ButtonMain
                onClick={() => apply(job)}
                style={{ marginBottom: 80 }}
              >
                {t('Ansök nu')}
              </ButtonMain>
            </Col>
            <Col md={2} />
          </Row>
        </StyledContainer>
      </PageWrapper>
    </>
  )
})

const apply = (job: any) => {
  document
    .getElementsByClassName('modal')[0]
    .setAttribute(
      'src',
      job.links && job.links['careersite-job-apply-iframe-url']
    )
  const modalHolder = document.getElementsByClassName('modalHolder')[0]
  // @ts-ignore
  modalHolder.style.display = 'flex'
}

const StyledContainer = styled(Container)`
  margin: 30px 0;

  @media (min-width: 769px) {
    margin-top: 85px;
    margin-bottom: 0;
  }
`

const HeadlineHolder = styled.div`
  position: absolute;
  bottom: 20px;
  width: 80%;

  @media (min-width: 769px) {
    bottom: 30px;
  }

  h1 {
    @media (max-width: 575px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media (min-width: 769px) and (max-width: 992px) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  h2 {
    font-size: 16px;
    line-height: 18px;
    color: ${colors.white};

    @media (min-width: 576px) {
      font-size: 26px;
      line-height: 30px;
    }

    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 46px;
      margin-top: 20px;
    }
  }
`

const Headline = styled.h1`
  font-size: 80px;
  line-height: 72px;
`

const HeroHolder = styled.div`
  position: relative;
  margin-top: 90px;
`

const HeroImg = styled.div`
  background-color: #000000;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.6) 90%
    ),
    url(${(props: any) => props.src});
  background-position: center bottom, center center;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 500px;
  height: 500px;
  width: 100%;
`

const StyledContent = styled(Ingress)`
  margin-top: 40px;

  @media (min-width: 769px) {
    margin-top: 0;
  }

  .blockquote-wrapper {
    display: flex;
    padding: 0 20px;

    blockquote {
      position: relative;
      font-family: 'Barlow Condensed', sans-serif;
      margin: 40px auto 60px;
      align-self: center;

      h1 {
        font-family: 'Miriam Libre';
        position: relative; /* for pseudos */
        color: ${colors.white};
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.3;
        margin: 0;
        border: 2px solid ${colors.orange};
        padding: 25px;

        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: 60px;
          bottom: -52px;
          left: 70px;
          z-index: 3;
          transform: rotate(-45deg);
          background-color: ${colors.orange};
        }

        &:before {
          content: '';
          position: absolute;
          width: 40px;
          border: 6px solid ${colors.green};
          bottom: -3px;
          left: 50px;
          z-index: 2;
        }
      }

      h4 {
        position: relative;
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        padding-top: 15px;
        z-index: 1;
        margin-left: 120px;
        padding-left: 12px;

        &:first-letter {
          margin-left: -12px;
        }
      }
    }
  }

  li {
    list-style: disc;
  }
`

export default Job
