import { makeAutoObservable } from 'mobx'
import { colors } from '../variables'

class uiStore {
  backgroundShade = colors.green
  footerBg = ''
  mobileMenuOpen = false
  footerHeight = 0

  constructor() {
    makeAutoObservable(this)
  }

  setBackgroundShade = (shade: string) => {
    this.backgroundShade = shade
    this.setFooterBg('')
    document.body.style.backgroundColor = shade
  }

  setFooterBg = (color: string) => {
    this.footerBg = color
  }

  toggleMobileMenu = (open: boolean) => {
    this.mobileMenuOpen = open
  }

  setFooterHeight = (height: number) => {
    this.footerHeight = height
  }
}

export const UIStore = new uiStore()
