import { colors } from './variables'
import i18n from './i18n'

import emanuel from './img/photos/stories/emanuel.jpg'
import man2 from './img/photos/stories/man2.jpg'
import adam from './img/photos/stories/adam.jpg'
import david from './img/photos/stories/david.jpg'
import daniel from './img/photos/stories/daniel.jpg'

const stories = [
  {
    id: 'emanuel',
    name: 'Emanuel Fratini',
    headline: i18n.t('stories.emanuel.headline'),
    img: emanuel,
    alignment: 'right',
    color: colors.white,
    ingress: i18n.t('stories.emanuel.ingress'),
    bread: i18n.t('stories.emanuel.bread'),
  },
  {
    id: 'david',
    name: 'David Karlsson',
    headline: i18n.t('stories.david.headline'),
    img: david,
    alignment: 'right',
    color: colors.white,
    ingress: i18n.t('stories.david.ingress'),
    bread: i18n.t('stories.david.bread'),
  },
  {
    id: 'adam',
    name: 'Adam Heidmark',
    headline: i18n.t('stories.adam.headline'),
    img: adam,
    alignment: 'right',
    color: colors.white,
    ingress: i18n.t('stories.adam.ingress'),
    bread: i18n.t('stories.adam.bread'),
  },
  {
    id: 'engdahl',
    name: 'Daniel Engdahl',
    headline: i18n.t('stories.engdahl.headline'),
    img: daniel,
    alignment: 'left',
    color: colors.green,
    ingress: i18n.t('stories.engdahl.ingress'),
    bread: i18n.t('stories.engdahl.bread'),
  },
  {
    id: 'tobias',
    name: 'Tobias Sjösten',
    headline: i18n.t('stories.tobias.headline'),
    img: man2,
    alignment: 'right',
    color: colors.green,
    ingress: i18n.t('stories.tobias.ingress'),
    bread: i18n.t('stories.tobias.bread'),
  },
]

export default stories
