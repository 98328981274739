import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { UIStore } from '../stores/UIStore'
import { colors } from '../variables'
import styled from 'styled-components'
import { Payoff, SubHeader, ButtonMain, PageWrapper } from '../components/Utils'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import { useHistory } from 'react-router-dom'

import { StoryProps } from './Story'
import stories from '../stories'
import { useTranslation } from 'react-i18next'

const About = observer(() => {
  useEffect(() => {
    UIStore.setBackgroundShade(colors.green)
    UIStore.setFooterBg(colors.white)
  }, [])

  return (
    <>
      <LandingArea />
      <PageWrapper
        style={{
          backgroundColor: colors.green,
        }}
      >
        <Container style={{ width: '100%', padding: 0 }}>
          {stories.map((story) => {
            return (
              <StoryItem
                story={{
                  id: story.id,
                  img: story.img,
                  name: story.name,
                  headline: story.headline,
                  ingress: story.ingress,
                  bread: story.bread,
                }}
                color={story.color}
                alignment={story.alignment}
                key={story.id}
              />
            )
          })}
        </Container>
      </PageWrapper>
    </>
  )
})

const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <LandingHolder>
      <Container>
        <Row>
          <Col xs={10}>
            <h1>{t('stories.header')}</h1>
          </Col>
        </Row>
      </Container>
    </LandingHolder>
  )
}

export const StoryItem = ({
  story,
  color,
  alignment,
}: {
  story: StoryProps
  color: string
  alignment: string
}) => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Hidden xs sm md>
        <StoryItemHolder
          imgRight={story.imgRight}
          background={story.img}
          alignment={alignment}
        >
          <StoryItemTextHolder color={color} alignment={alignment}>
            <div style={{ position: 'relative' }}>
              <SubHeader>{story.name}</SubHeader>
              <Payoff>{t(`stories.${story.id}.headline`)}</Payoff>
              <ButtonMain
                onClick={() => {
                  history.push(`/story/${story.id}`)
                  window.scrollTo(0, 0)
                }}
              >
                {t('stories.Läs hela storyn')}
              </ButtonMain>
            </div>
          </StoryItemTextHolder>
        </StoryItemHolder>
      </Hidden>
      <Visible xs sm md>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={story.img}
            style={{ width: 'calc(100% + 40px)', margin: '0 -20px' }}
            alt={story.name}
          />
          <StoryItemTextHolder color={colors.white} alignment={alignment}>
            <div style={{ position: 'relative' }}>
              <SubHeader>{story.name}</SubHeader>
              <Payoff>{story.headline}</Payoff>
              <ButtonMain
                onClick={() => {
                  history.push(`/story/${story.id}`)
                  window.scrollTo(0, 0)
                }}
              >
                {t('stories.Läs hela storyn')}
              </ButtonMain>
            </div>
          </StoryItemTextHolder>
        </div>
      </Visible>
    </>
  )
}

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
  margin-bottom: 80px;

  h1 {
    margin-bottom: 20px;
  }

  @media (min-width: 769px) {
    margin-bottom: 140px;
  }
`

const StoryItemHolder = styled.section`
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-image: ${({ background }: { background: string }) =>
    `url(${background})`};
  background-position: center center;
  width: 100%;
  justify-content: ${({ alignment }: { alignment: string }) =>
    alignment === 'left' ? 'flex-start' : 'flex-end'};
  margin-bottom: 80px;

  @media (min-width: 769px) {
    flex-direction: ${({ imgRight }: { imgRight: boolean }) =>
      imgRight ? 'row-reverse' : 'row'};
    height: 680px;
  }
`

const StoryItemTextHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${({ color }: { color: string }) => color};
  width: calc(100% + 40px);
  height: 100%;
  padding: 30px;
  position: relative;
  box-sizing: border-box;
  margin: 0 -20px 40px;

  h2 {
    margin-top: 0;
    color: inherit;
  }

  ${Payoff} {
    color: inherit;
  }

  button {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    ${({ alignment }: { alignment: string }) =>
      alignment === 'left' ? 'padding-left: 80px;' : 'padding-right: 80px;'};
    max-width: 40%;
    width: 40%;
    text-align: ${({ alignment }: { alignment: string }) =>
      alignment === 'left' ? 'left' : 'right'};
    margin: 0;
  }
`

export default About
