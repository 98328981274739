import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import {
  SubHeader,
  Ingress,
  Payoff,
  ImageBlock,
  PageWrapper,
  PixelStairs,
  ButtonMain,
} from '../components/Utils'
import { colors } from '../variables'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import writing from '../img/photos/writing.jpg'
import dog from '../img/photos/dog.jpg'
import baby from '../img/photos/baby.jpg'
import { UIStore } from '../stores/UIStore'
import { Container, Row, Col, Hidden } from 'react-grid-system'

import engdahldn from '../img/photos/engdahldn.jpg'
import { StyledImgSet } from './Main'
import { useTranslation } from 'react-i18next'

const Work = observer(() => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
    UIStore.setBackgroundShade(colors.white)
  })

  return (
    <>
      <LandingArea />
      <PageWrapper style={{ backgroundColor: colors.green }}>
        <Container>
          <Row style={{ alignItems: 'center', marginTop: 40 }}>
            <Hidden xs sm md>
              <Col lg={1} />
            </Hidden>
            <Col xs={12} md={6} lg={5}>
              <SubHeader style={{ color: colors.orange }}>
                {t('work.text1.header')}
              </SubHeader>
              <Ingress>
                {t('work.text1.text')}
                <br />
                <br />
                <Link to={'/stories'} onClick={() => window.scrollTo(0, 0)}>
                  {t('work.stories')}
                </Link>
              </Ingress>
            </Col>
            <Col xs={12} md={6}>
              <DevImg bg={engdahldn} title="Daniel Engdahl | Tech Lead">
                <div />
                <DevImgText>
                  <h3>Daniel Engdahl</h3>
                  <span>Tech Lead / Utvecklare</span>
                </DevImgText>
              </DevImg>
            </Col>
          </Row>
          <StyledRow>
            <Hidden xs sm md>
              <Col lg={1} />
            </Hidden>
            <StyledColLeft xs={12} lg={10}>
              <SubHeader style={{ color: colors.orange }}>
                {t('work.text2.header')}
              </SubHeader>
              <Ingress>{t('work.text2.text')}</Ingress>
            </StyledColLeft>
            <Hidden xs sm md>
              <Col lg={1} />
            </Hidden>
          </StyledRow>
        </Container>
      </PageWrapper>
      <PixelStairs
        color={colors.orange}
        background={colors.green}
        contentBackground={colors.orange}
      >
        <Container>
          <Row>
            <Col xs={12} lg={4}>
              <SubHeader>{t('work.trappan.steg1.header')}</SubHeader>
              <Ingress>{t('work.trappan.steg1.text')}</Ingress>
            </Col>
            <Col xs={12} lg={4}>
              <SubHeader>{t('work.trappan.steg2.header')}</SubHeader>
              <Ingress>{t('work.trappan.steg2.text')}</Ingress>
            </Col>
            <Col xs={12} lg={4}>
              <SubHeader>{t('work.trappan.steg3.header')}</SubHeader>
              <Ingress>{t('work.trappan.steg3.text')}</Ingress>
            </Col>
          </Row>
        </Container>
      </PixelStairs>
      <PageWrapper style={{ backgroundColor: colors.orange }}>
        <Container>
          <Row style={{ alignItems: 'center', paddingBottom: 100 }}>
            <Col xs={12}>
              <ButtonMain
                onClick={() => {
                  history.push('/stories')
                  window.scrollTo(0, 0)
                }}
              >
                {t('Våra stories')}
              </ButtonMain>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </>
  )
})

export const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <LandingHolder>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <h1>{t('work.header')}</h1>
          </Col>
          <Hidden xs sm md>
            <Col lg={6}>
              <ImageBlock style={{ left: 30, top: 100 }}>
                <StyledImgSet img={writing} className="block1-img1" />
                <StyledImgSet img={dog} className="block1-img2" />
                <StyledImgSet img={baby} className="block1-img3" />
              </ImageBlock>
            </Col>
          </Hidden>
        </Row>
        <Row>
          <Col xs={12}>
            <Payoff
              style={{ color: colors.green, marginTop: 60, marginBottom: 90 }}
            >
              {t('work.payoff')}
            </Payoff>
          </Col>
        </Row>
      </Container>
    </LandingHolder>
  )
}

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
`

const StyledColLeft = styled(Col)`
  margin-top: 40px;

  @media (min-width: 769px) {
    margin-top: 0;
    padding-right: 40px;
  }
`

const StyledRow = styled(Row)`
  margin-bottom: 40px;

  @media (min-width: 769px) {
    margin-top: 100px;
    margin-bottom: 140px;
  }
`

const DevImg = styled.div`
  width: 500px;
  height: 500px;
  background-image: ${({ bg }: { bg: string }) => `url(${bg})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  & > div:first-child {
    background-color: ${colors.orange};
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;

    @media (min-width: 992px) {
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
    }
  }

  @media (max-width: 480px) {
    max-width: 100vw;
    max-height: 400px;
    margin: 40px -35px 0;
  }

  @media (min-width: 321px) and (max-width: 768px) {
    margin: 40px 0 0;
    max-width: calc(100vw - 70px);
  }

  @media (min-width: 769px) {
    margin-left: 0;
    margin-top: 100px;
    max-height: 100%;
    max-width: 100%;
  }

  @media (min-width: 992px) {
    margin-left: 60px;
  }
`

const DevImgText = styled.div`
  background-color: ${colors.orange};
  height: 80px;
  width: 100%;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  text-align: right;

  @media (min-width: 992px) {
    height: 120px;
  }

  h3 {
    font-size: 24px;
    text-transform: uppercase;
    color: ${colors.green};

    @media (min-width: 992px) {
      font-size: 32px;
    }
  }

  span {
    font-family: 'Miriam Libre';
    font-size: 15px;
    margin-top: 4px;
    color: ${colors.white};

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
`

export default Work
