import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import {
  ImgSet,
  Ingress,
  Payoff,
  SubHeader,
  ImageBlock,
  PageWrapper,
} from '../components/Utils'
import { colors } from '../variables'

import conferenceroom from '../img/photos/conferenceroom.jpg'
import working from '../img/photos/working_square.jpg'
import people from '../img/photos/people.jpg'
import { UIStore } from '../stores/UIStore'
import bonniernews from '../img/photos/bonniernews.jpg'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

const Developers = observer(() => {
  useEffect(() => {
    UIStore.setBackgroundShade(colors.white)
  })

  const { t } = useTranslation()

  return (
    <>
      <LandingArea />
      <PageWrapper
        style={{
          backgroundColor: colors.white,
        }}
      >
        <Container>
          <Row style={{ marginTop: 60 }}>
            <Hidden xs sm md>
              <Col xs={12} lg={6}>
                <ImageBlock>
                  <ImgSet
                    img={conferenceroom}
                    style={{ top: 200, left: -80, width: 215 }}
                  />
                  <ImgSet
                    img={working}
                    style={{ top: 0, left: 165, height: 396 }}
                  />
                  <ImgSet
                    img={people}
                    style={{ top: 430, left: 165, width: 240 }}
                  />
                </ImageBlock>
              </Col>
            </Hidden>
            <Col xs={12} lg={6}>
              <StyledPayoff shade="dark">{t('customers.payoff')}</StyledPayoff>
            </Col>
          </Row>
          <StyledRow>
            <Col xs={12} lg={4}>
              <SubHeader shade="dark">{t('customers.text1.header')}</SubHeader>
              <Ingress shade="dark">{t('customers.text1.text')}</Ingress>
            </Col>
            <Col xs={12} lg={4}>
              <SubHeader shade="dark">{t('customers.text2.header')}</SubHeader>
              <Ingress shade="dark">{t('customers.text2.text')}</Ingress>
            </Col>
            <Col xs={12} lg={4}>
              <SubHeader shade="dark">{t('customers.text3.header')}</SubHeader>
              <Ingress shade="dark">{t('customers.text3.text')}</Ingress>
            </Col>
          </StyledRow>
        </Container>
      </PageWrapper>
      <CustomerQuote
        quote={{
          img: bonniernews,
          person: 'Peter Frey',
          title: 'fd. CIO',
          company: 'Bonnier News',
          text: t('customers.quote'),
        }}
      />
    </>
  )
})

export const LandingArea = () => {
  const { t } = useTranslation()

  return (
    <LandingHolder>
      <Container>
        <Row>
          <Col sm={9}>
            <h1>{t('customers.header')}</h1>
          </Col>
        </Row>
      </Container>
    </LandingHolder>
  )
}

type QuoteProps = {
  img: string
  person: string
  title: string
  company: string
  text: string
}

export const CustomerQuote = ({ quote }: { quote: QuoteProps }) => {
  return (
    <QuoteHolder>
      <QuoteImg img={quote.img} />
      <QuoteTextHolder>
        <div style={{ position: 'relative' }}>
          <Quotes style={{ top: '-5px', left: '-40px' }}>&ldquo;</Quotes>
          <Quotes style={{ bottom: '30px', right: '-30px' }}>&rdquo;</Quotes>
          <Payoff
            style={{ flexBasis: 'auto', width: 'auto', textAlign: 'center' }}
          >
            {quote.text}
          </Payoff>
          <span
            style={{
              display: 'inline-block',
              marginTop: 25,
              fontStyle: 'italic',
              fontSize: '18px',
              lineHeight: '26px',
            }}
          >
            {quote.person} &mdash; {quote.title}, {quote.company}
          </span>
        </div>
      </QuoteTextHolder>
    </QuoteHolder>
  )
}

const LandingHolder = styled.div`
  padding-top: 140px;
  box-sizing: border-box;
`

const StyledPayoff = styled(Payoff)`
  @media (min-width: 992px) and (max-width: 1279px) {
    margin-left: 90px;
    width: 80%;
  }
`

const StyledRow = styled(Row)`
  margin-top: 60px;

  @media (min-width: 992px) {
    margin-top: 240px;
  }

  @media (min-width: 1280px) {
    margin-top: 400px;
  }
`

const QuoteHolder = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 60px;

  @media (min-width: 769px) {
    flex-direction: row;
    margin-top: 100px;
    height: 680px;
  }
`

const QuoteImg = styled.div`
  background-image: url(${({ img }: { img: string }) => img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-width: 100vw;
  height: 300px;

  @media (min-width: 769px) {
    min-width: 50vw;
    height: 100%;
  }
`

const QuoteTextHolder = styled.div`
  background-color: ${colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  width: 100%;
  height: 100%;
  padding: 70px;
  position: relative;
  text-align: center;
  box-sizing: border-box;

  @media (min-width: 769px) {
    padding: 0 120px;
    max-width: 50vw;
  }
`

const Quotes = styled.div`
  position: absolute;
  color: ${colors.orange};
  font-size: 100px;
  line-height: 0;
`

export default Developers
