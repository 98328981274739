import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { v4 as uuid } from 'uuid'
import { useTranslation } from 'react-i18next'

import { UIStore } from '../stores/UIStore'
import { observer } from 'mobx-react'
import { colors } from '../variables'

import flag_se from '../img/icons/flag_se.png'
import flag_uk from '../img/icons/flag_uk.png'

type MenuItemProps = {
  title: string
  link: string
  children?: [{ title: string; link: string }]
}

const getMenuItems = () => {
  const mnuItems: MenuItemProps[] = [
    { title: 'Jobba med oss', link: 'work' },
    { title: 'Lediga jobb', link: 'career' },
    { title: 'För kunder', link: 'customers' },
    { title: 'Referenser', link: 'references' },
    { title: 'Våra stories', link: 'stories' },
    { title: 'Kontakt', link: 'contact' },
  ]

  return mnuItems
}

const MenuItems = observer(() => {
  const { t, i18n } = useTranslation()
  const menuitems = getMenuItems()
  const location = useLocation()
  const path = location.pathname.substring(1)

  return (
    <ul>
      {menuitems.map((page) => {
        return (
          <React.Fragment key={uuid()}>
            <StyledLi
              key={uuid()}
              color={
                path === page.link
                  ? colors.orange
                  : UIStore.backgroundShade === colors.green
                  ? colors.white
                  : colors.green
              }
            >
              <Link to={`/${page.link}`} onClick={() => window.scrollTo(0, 0)}>
                {t(page.title)}
              </Link>
            </StyledLi>
          </React.Fragment>
        )
      })}
      <li style={{ display: 'flex', flexDirection: 'row', columnGap: 10 }}>
        <img
          src={flag_se}
          alt="Svenska"
          onClick={() => i18n.changeLanguage('sv')}
          key="sv"
          style={{ height: 25, cursor: 'pointer' }}
        />
        <img
          src={flag_uk}
          alt="English"
          onClick={() => i18n.changeLanguage('en')}
          key="en"
          style={{ height: 25, cursor: 'pointer' }}
        />
      </li>
    </ul>
  )
})

export const MobileMenuItems = () => {
  const menuitems = getMenuItems()
  const location = useLocation()
  const path = location.pathname.substring(1)
  const { t, i18n } = useTranslation()

  return (
    <ul>
      {menuitems.map((page) => {
        return (
          <React.Fragment key={uuid()}>
            <StyledLi
              key={uuid()}
              color={path === page.link ? colors.orange : colors.white}
            >
              <Link
                to={`/${page.link}`}
                onClick={() => {
                  window.scrollTo(0, 0)
                  UIStore.toggleMobileMenu(false)
                }}
                style={{ fontSize: 32 }}
              >
                {t(page.title)}
              </Link>
            </StyledLi>
          </React.Fragment>
        )
      })}
      <li
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 10,
          marginTop: 40,
        }}
      >
        <img
          src={flag_se}
          alt="Svenska"
          onClick={() => {
            i18n.changeLanguage('sv')
            window.scrollTo(0, 0)
            UIStore.toggleMobileMenu(false)
          }}
          key="sv"
          style={{ height: 25, cursor: 'pointer' }}
        />
        <img
          src={flag_uk}
          alt="English"
          onClick={() => {
            i18n.changeLanguage('en')
            window.scrollTo(0, 0)
            UIStore.toggleMobileMenu(false)
          }}
          key="en"
          style={{ height: 25, cursor: 'pointer' }}
        />
      </li>
    </ul>
  )
}

const StyledLi = styled.li`
  margin-right: 40px;
  white-space: nowrap;
  user-select: none;
  margin-bottom: 12px;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }

  @media (min-width: 992px) and (max-width: 1279px) {
    margin-right: 16px;
  }

  a {
    font-family: Gelion;
    font-size: 18px;
    color: ${({ color }: { color: string }) => color};
    letter-spacing: 0.8px;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }
`

export default MenuItems
